<template>
  <div class="module-goods">
    <vxe-grid
      ref="refTable"
      v-bind="tableOptions"
      :columns="tableColumnList"
      :data="goodsList"
      :toolbar-config="{ zoom: true, custom: true }"
      @custom="columnCustomConfirm"
      @resizable-change="columnResizableChange"
    >
      <template v-if="isShowList" #pager>
        <div></div>
        <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
      </template>
    </vxe-grid>
  </div>
</template>

<script>
export default {
  props: {
    categoryLastId: {
      type: [String, Number],
      required: true
    },
    caizhiName: {
      type: [String, Number],
      default: ''
    },
    isShowList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableOptions: {
        loading: this.isShowList,
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        height: this.isShowList ? this.$util.getViewHeight() - 489 : 400, // 527
        resizable: true,
        columns: []
      },
      pageOptions: {
        ...this.$constant.page
      },
      columnList: [], // 库存头部列表
      goodsList: [{}] // 库存商品列表
    }
  },
  computed: {
    // 表格-库存头部列表
    tableColumnList() {
      let result = [
        {
          type: 'checkbox',
          width: 40
        }
      ]
      this.columnList.forEach((item) => {
        let obj = {
          title: item.title,
          width: item.width || 100,
          field: item.field,
          params: {
            id: item.id
          }
        }
        result.push(obj)
      })
      return result
    }
  },
  watch: {
    caizhiName(val) {
      if (val) {
        this.initTableOptions()
      }
    },
    // 库存商品列表
    goodsList: {
      handler(val) {
        if (JSON.stringify(this.list) !== JSON.stringify(val)) {
          this.$emit('update:list', this.$util.deepCopy(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 初始化表格配置
    initTableOptions() {
      this.getColumnList()
      this.getListData()
    },
    // 获取模块-库存头部列表
    getColumnList() {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/dict/getColumnHead',
        params: {
          column_data_id: this.categoryLastId
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.columnList = res.data.list
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取模块-库存商品列表
    getListData() {
      this.tableOptions.loading = true
      let filterData = {
        column_data_id: this.categoryLastId
      }
      if (this.caizhiName) filterData.texture = this.caizhiName

      this.$api({
        method: 'post',
        url: '/admin/gcvip/stock/list',
        data: {
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData,
          sortData: {},
          formData: {}
        }
      })
        .then((res) => {
          // 接口调试中
          if (res.data.state === 'ok') {
            if (this.isShowList) {
              this.goodsList = res.data.page.list
            } else {
              this.goodsList = [{}]
            }
            this.pageOptions.pageSize = res.data.page.pageSize
            this.pageOptions.total = res.data.page.totalRow
            this.pageOptions.currentPage = res.data.page.pageNumber
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch(() => {})
        .then(() => {
          this.tableOptions.loading = false
        })
    },
    // 分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 列-自定义排序确认
    columnCustomConfirm(data) {
      if (data.type === 'confirm') {
        let columns = data.$grid.getColumns()
        columns.forEach((item) => {
          item.id = item.params.id
        })
        this.columnList = columns
        this.columnListSave()
      }
    },
    // 列-列宽更新
    columnResizableChange(data) {
      this.columnList.forEach((item) => {
        if (item.field === data.column.field) {
          item.width = data.column.renderWidth
        }
      })
      this.columnListSave()
    },
    // 列-列表保存
    columnListSave() {
      let result = this.columnList.map((item) => {
        return {
          id: item.id,
          title: item.title,
          field: item.field,
          width: item.width
        }
      })
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dict/saveColumnList',
        data: {
          list: result
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success(res.data.msg)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.module-goods {
  margin-top: -38px;
  /deep/ .vxe-table-custom-wrapper {
    // max-height: 390px !important;
    top: 0;
    right: 0;
    border-radius: 4px 0 0 4px;
  }
  /deep/ .vxe-table-custom--list-wrapper {
    .scroll-bar();
  }
  /deep/ .vxe-table-custom--header,
  /deep/ .vxe-table-custom--checkbox-option,
  /deep/ .vxe-table-custom--fixed-option {
    display: none;
  }
}
</style>
